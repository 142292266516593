export class DsObservable {
    constructor() {
        this.subscriptions = new Map();
    }
    get value() {
        return this._value;
    }
    subscribe(callback) {
        const subscription = new ObservableSubscription(this.subscriptions);
        this.subscriptions.set(subscription, callback);
        return subscription;
    }
}
/**
 * Ein einfaches Observables an das man sich anmelden und wieder abmelden kann, sobald sich der interne Wert verändert
 */
export class DsSubject extends DsObservable {
    constructor(value) {
        super();
        this._value = value;
    }
    next(newValue) {
        this._value = newValue;
        this.subscriptions.forEach((callbackFunction) => {
            callbackFunction(this._value);
        });
    }
}
export class ObservableSubscription {
    constructor(map) {
        this.map = map;
    }
    unsubscribe() {
        this.map.delete(this);
    }
}
