import { DsSubject } from "../../../classes/multicast-observable";
import { ValueStore } from "../../../classes/value-store";
const HEADER_SERVICE_KEY = "dx-header-service";
export class HeaderService {
    constructor() {
        this.headerSubject = new DsSubject();
        this.headerHeightSubject = new DsSubject();
        this.headerVisibilitySubject = new DsSubject();
        this.scrollInteractionEnabled = true;
    }
    static instance() {
        return ValueStore.instance().getOrPut(HEADER_SERVICE_KEY, () => new HeaderService());
    }
    get header$() {
        return this.headerSubject;
    }
    get header() {
        return this.headerSubject.value;
    }
    set header(header) {
        var _a;
        if (this.headerSubject.value !== header) {
            (_a = this.headerResizeObserver) === null || _a === void 0 ? void 0 : _a.disconnect();
            this.headerSubject.next(header);
            if (header == null) {
                return;
            }
            this.headerResizeObserver = new ResizeObserver(() => {
                var _a, _b;
                this.headerHeightSubject.next((_b = (_a = this.headerSubject.value) === null || _a === void 0 ? void 0 : _a.offsetHeight) !== null && _b !== void 0 ? _b : 0);
            });
            this.headerResizeObserver.observe(header);
        }
    }
    unsetHeaderElement(header) {
        if (this.header === header) {
            this.header = null;
        }
    }
    get headerHeight$() {
        return this.headerHeightSubject;
    }
    get headerHeight() {
        var _a;
        return (_a = this.headerHeightSubject.value) !== null && _a !== void 0 ? _a : 0;
    }
    get isVisible() {
        return this.headerVisibilitySubject.value;
    }
    set isVisible(isVisible) {
        if (this.isVisible != isVisible) {
            this.headerVisibilitySubject.next(isVisible);
        }
    }
    get isVisible$() {
        return this.headerVisibilitySubject;
    }
    get isScrollInteractionEnabled() {
        return this.scrollInteractionEnabled;
    }
    disableScrollInteractionForMs(timeMs) {
        clearTimeout(this.disableScrollInteractionTimeoutHandle);
        return new Promise((resolve) => {
            this.scrollInteractionEnabled = false;
            this.disableScrollInteractionTimeoutHandle = setTimeout(() => {
                this.scrollInteractionEnabled = true;
                resolve();
            }, timeMs);
        });
    }
}
export function subscribeToHeaderHeight(heightChangeCallback) {
    const headerServiceInstance = HeaderService.instance();
    const fireCallback = () => heightChangeCallback(headerServiceInstance.headerHeight, headerServiceInstance.isVisible);
    const isVisibleSubscription = headerServiceInstance.isVisible$.subscribe(() => fireCallback());
    const headerHeightSubscription = headerServiceInstance.headerHeight$.subscribe(() => fireCallback());
    return {
        unsubscribe: () => {
            isVisibleSubscription.unsubscribe();
            headerHeightSubscription.unsubscribe();
        },
    };
}
